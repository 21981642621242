import React from "react"
import Fade from 'react-reveal/Fade';
import "../styles/plans.scss";
import plans from '../data/plans.json'

export const Plans = () => {
    return (
        <div id="plans" className="plans bg-white">
            <div className="container py-6">
                <h2 className="text-uppercase text-center mb-5">Planes</h2>
                    <Fade up cascade>
                        <div className="row g-5">
                            {plans.map(
                                (plan, index) =>  <Plan key={index} {...plan}/>
                            )}
                        </div>
                    </Fade>

            </div>
        </div>
    )
}


const Plan = ({title, features = [], price = 0, best = false}) => (
    <div className={best ? "col-lg-4 over" : "col-lg-4"}>
        <div className={best ? "card h-100 plan best" : "card h-100 plan"}>
            <div className="card-header">
                <h3>{title}</h3>
            </div>
            <div className="card-body">
                <ul>
                    {features.map((feature, index) => (
                        <li key={index}>{feature}</li>
                    ))}
                </ul>
            </div>
            <div className="card-footer p-4">
                <span className="plan-price">
                    {price.toLocaleString('es-ES', {minimumIntegerDigits: 1})} $
                </span><br/>
                <span>Mensuales</span>
            </div>
        </div>
    </div>

)