import React from "react";
import Logo from "../images/image1010.png";
import "../styles/footer.scss";
import { Link } from "gatsby";

export const Footer = () => {
  return (
    <>
      <footer className="footer bg-white py-7">
        <div className="container">
          <div className="row gx-5 gy-4">
            <div className="col-lg-3">
              <div className="mb-4 text-center">
                <img
                  src={Logo}
                  className="img-fluid"
                  alt={"Logo"}
                  style={{ maxWidth: "250px" }}
                />
              </div>
              <div className="d-flex flex-row justify-content-center">
                <a
                  className="icon-circle link-text"
                  href="https://www.instagram.com/mygoodapp.oficial/"
                >
                  <i className="bi bi-instagram" />
                </a>
                <a className="icon-circle link-text" href="https://www.facebook.com/mygoodapp.oficial">
                  <i className="bi bi-facebook" />
                </a>
                <a
                  className="icon-circle link-text"
                  href="https://www.tiktok.com/@mygoodapp?lang=es"
                >
                  <i className="fab fa-tiktok" />
                </a>
                <a className="icon-circle link-text" href="https://www.twitch.tv/mygoodapp">
                    <i className="bi bi-twitch"/>
                </a>
                <a className="icon-circle link-text" href="https://www.pinterest.es/mygoodapp/">
                    <i className="fab fa-pinterest"/>
                </a>
              </div>
            </div>
            <div className="col-lg-3">
              <nav className="nav flex-column text-center text-lg-start">
                <Link className="nav-link link-dark " to="/#">
                  Descargar App
                </Link>
                <Link className="nav-link link-dark" to="/#">
                  Registrate Gratis
                </Link>
                <Link className="nav-link link-dark" to="/#">
                  Inicia Sesión
                </Link>
              </nav>
            </div>
            <div className="col-lg-3">
              <nav className="nav flex-column  text-center text-lg-start">
                <Link className="nav-link link-dark" to="/why">
                  ¿Por qué MyGood App?
                </Link>
                <Link className="nav-link link-dark" to="/#plans">
                  Planes
                </Link>
                <Link className="nav-link link-dark" to="/#">
                  Especialistas
                </Link>
                <Link className="nav-link link-dark" to="/#">
                  Farmacias
                </Link>
              </nav>
            </div>
            <div className="col-lg-3">
              <nav className="nav flex-column  text-center text-lg-start">
                <span className="nav-link link-dark">Contacto:</span>
                <a
                  className="nav-link link-dark"
                  href="mailto:contacto@mygoodapp.com"
                >
                  <strong>contacto@mygoodapp.com</strong>
                </a>
                {/*<a className="nav-link link-dark" href="tel:00582128262398">
                  <strong>+58 212 8262398</strong>
</a>*/}
              </nav>
            </div>
          </div>
        </div>
      </footer>
      <div className="bg-light py-3">
        <div className="container text-center d-flex flex-column flex-md-row justify-content-between">
          <span>MyGoodApp - 2021</span>
          <span>
            <small>
              Creado por{" "}
              <a
                href="https://conceptualdynamic.com/"
                target="_blank"
                rel="noreferrer"
              >
                Conceptual Dynamic
              </a>{" "}
              &amp; hecho con <i className="bi bi-heart-fill text-danger" />
            </small>
          </span>
        </div>
      </div>
    </>
  );
};
