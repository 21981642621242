import React from "react";
import "../styles/content.scss"
import ContentImg3 from "../images/pexels-karolina-grabowska-4021779.jpg";
import ContentImg4 from "../images/pexels-pixabay-40568.jpg";


export const Content3 = () => (
    <>
        <div className="content bg-white py-5">
            <div className="container py-5">
                <div className="row g-4">
                    <article className="col-md-6">
                        <h3>El doctor va a tu centro de trabajo</h3>
                        <p>Los empleadores podrán contar con atención médica remota para su centro de trabajo. Además,
                            disfrutarán de acceso a estadísticas de incidentes de salud laboral de sus empleados y/o
                            colaboradores.</p>
                        <a href="http://www.google.com" className="btn btn-primary px-5">Descargar App</a>
                    </article>
                    <div className="col-md-6">
                        <img className="img-fluid" alt={""} src={ContentImg3}/>
                    </div>
                </div>
            </div>
        </div>
        <div className="content bg-white py-5">
            <div className="container py-5">
                <div className="row g-4">

                    <div className="col-md-6">
                        <img className="img-fluid" alt={""} src={ContentImg4}/>
                    </div>
                    <article className="col-md-6 ">
                        <h3>Estadísticas fiables para renovar inventario</h3>
                        <p>Con MyGood App, los proveedores de servicios de salud no sólo amplían sus canales de
                            comercialización y de comunicación. El acceso a estadísticas de consumo y de salud les
                            permitirá renovar de manera inteligente su inventario y orientar su inversión.</p>
                        <a href="http://www.google.com" className="btn btn-primary px-5">Descargar App</a>
                    </article>
                </div>
            </div>
        </div>
    </>

)