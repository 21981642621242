import React from "react"
import Fade from 'react-reveal/Fade';

import Image1 from "../images/physician-consulting-his-patient-clinic-min.png"
import Image2 from "../images/woman-doctor-recording-vlog-video-about-medicin.png"
import Image3 from "../images/portrait-of-happy-friendly-young-medical-doctor-or-nurse-min.png"
import "../styles/promo.scss"

export const Promo = () => (
    <div className="promo bg-white">
        <Fade up>
            <div className="container">
                <h2 className="text-uppercase text-center mb-4">Más que un gestor de citas médicas</h2>
                <div className="row row-cols-1 row-cols-lg-3 g-3 g-lg-6">

                    <div className="col">
                        <div className="card h-100">
                            <img src={Image1} className="card-img-top" alt="..."/>
                            <div className="card-body">
                                <p className="card-text">Integra el servicio médico con el acceso a tratamiento.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card h-100">
                            <img src={Image2} className="card-img-top" alt="..."/>
                            <div className="card-body">
                                <p className="card-text">Complementa la consulta online con seguimiento remoto.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card h-100">
                            <img src={Image3} className="card-img-top" alt="..."/>
                            <div className="card-body">
                                <p className="card-text">Ofrece recomendaciones de salud personalizadas para cada
                                    paciente.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fade>
    </div>
);