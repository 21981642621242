import React from "react";
import Phone from "../images/21398646.png"
import File from "../images/file.png"
import Drugs from "../images/drugs.png"
import Checklist from "../images/to-do-list.png"
import Catalog from "../images/catalogo.png"
import Calender from "../images/Calendar.png"

import "../styles/circle.scss"
import Fade from "react-reveal/Fade";
import Zoom from "react-reveal/Zoom";

export const Circle = () => {
    return (
        <div className="circle bg-gradient-my-good-app py-1 pt-lg-5 pb-lg-1">
            <div className="container py-1 pt-lg-5 pb-lg-1">
                <div className="square">
                    <div className="outer-circle"/>
                    <div className="inner-circle"/>
                    <div className="content">
                        <Zoom>
                            <img src={Phone} alt={"..."}/>
                        </Zoom>
                    </div>

                    <div className="ring">
                        <Fade down cascade>
                            <div className="dot">
                                <div className="dot-content">
                                    <img src={Catalog} className="dot-icon" alt={""}/>
                                    <span>Prescripción médica digital</span>
                                </div>
                            </div>
                            <div className="dot">
                                <div className="dot-content">
                                    <img src={Checklist} className="dot-icon" alt={""}/>
                                    <span>Catálogo de proveedores de medicamentos y/o equipos</span>
                                </div>
                            </div>
                            <div className="dot">
                                <div className="dot-content">
                                    <img src={Calender} className="dot-icon" alt={""}/>
                                    <span>Gestor de citas <br/> (presenciales y online)</span>
                                    </div>
                            </div>
                            <div className="dot">
                                <div className="dot-content"><img src={Drugs} className="dot-icon" alt={""}/>
                                    <span>Ciclo de tratamientos</span>
                                </div>
                            </div>
                            <div className="dot">
                                <div className="dot-content">
                                    <img src={File} className="dot-icon" alt={""}/>
                                    <span>Historia Médica Digital bajo estándares internacionales</span>
                                </div>
                            </div>
                        </Fade>
                    </div>
                </div>
            </div>
        </div>
    )
}