import { Link } from "gatsby";
import React from "react";

import Logo from "../images/icon.png"

export const Navbar = () => (
    <nav className="navbar navbar-expand-lg navbar-light bg-white fixed-top">
        <div className="container">
            <span className="navbar-brand">
                <img className="logo" src={Logo} alt="..."/>
            </span>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                    aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"/>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                    <li className="nav-item mx-3">
                        <Link className="nav-link text-center" to="/" activeClassName="active">Home</Link>
                    </li>
                    <li className="nav-item mx-3">
                        <Link className="nav-link text-center" to="/why" activeClassName="active">¿Por qué MyGood App?</Link>
                    </li>
                    <li className="nav-item mx-3">
                        <Link className="nav-link text-center" activeClassName="active" to="/#plans">Planes</Link>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
);