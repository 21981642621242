import React from "react";
import "../styles/why.scss"
import Doctors from "../images/race-women-looking-young-horizontal-standing-min.png";

export const WhyHeader = () => {

    return (
        <div className="why bg-white">
            <div className="why-top">
                <img className="img-fluid doctors" src={Doctors} alt={""}/>
                <div className="filter"/>
            </div>
            <div className="container text-center py-5">
                <p>MyGood App es una plataforma de uso intuitivo y agradable que integra que integra la atención médica
                    con el acceso a medicamentos y equipos. Con MyGood App se automatizan procesos como la programación
                    de citas y la gestión de consultas médicas desde cualquier lugar; adaptando a los usuarios a los
                    nuevos esquemas de coexistencia de servicio presencial y remoto.</p>
            </div>
        </div>
    )
}