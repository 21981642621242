import React from "react";
import {Helmet} from "react-helmet";
import {useSiteMetadata} from "../hooks/use-site-metadata";


export function Layout(props) {
    const {
        title: siteTitle,
        description: siteDescription,
        keywords: siteKeywords,
        logo,
        author: siteAuthor,
        siteUrl,
        icon
    } = useSiteMetadata()

    const title = props.title ? props.title : siteTitle;
    const description = props.description ? props.description : siteDescription;
    const keywords = props.keywords ? props.keywords : siteKeywords;
    const url = props.url ? props.url : siteUrl
    const author = props.author ? props.author : siteAuthor;
    const image = props.image ? props.image : logo


    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{title}</title>
                <link rel="canonical" href={url}/>
                <meta name="title" content={title}/>
                <meta name="description" content={description}/>
                <meta name="keywords" content={keywords}/>
                <meta name="robots" content="index, follow"/>
                <meta name="language" content="Spanish"/>
                <meta name="author" content={author}/>
                <html lang="es"/>
                <meta name="image" content={image}/>
                <meta itemProp="name" content={title}/>
                <meta itemProp="description" content={description}/>
                <meta itemProp="image" content={image}/>

                <meta property="og:title" content={title}/>
                <meta property="og:site_name" content={siteTitle}/>
                <meta property="og:url" content={url}/>
                <meta property="og:image" content={image}/>

                <meta property="og:description" content={description}/>
                <meta property="og:type" content="website"/>
                <meta property="og:locale" content="es_VE"/>

                <meta property="twitter:card" content="summary_large_image"/>
                <meta property="twitter:url" content={url}/>
                <meta property="twitter:title" content={title}/>
                <meta property="twitter:description" content={description}/>
                <meta property="twitter:image" content={image}/>
                <link rel="icon" type="image/png" href={icon}/>
            </Helmet>
            <main>
                {props.children}
            </main>
        </>
    );
}