import {useStaticQuery, graphql} from "gatsby";

export const useSiteMetadata = () => {
    const {site} = useStaticQuery(
        graphql`
            query SiteMetaData {
                site {
                    siteMetadata {
                        title
                        siteUrl
                        keywords
                        author
                        description
                        logo
                        icon
                    }
                }
            }
        `
    )
    return site.siteMetadata;
}