import React from "react";
import "../styles/content.scss"
import ContentImg from "../images/pexels-anna-shvets-4225920.jpg";

export const Content1 = () => (
    <div className="content bg-white py-5">
        <div className="container py-5">
            <div className="row g-4">
                <article className="col-md-6">
                    <h3>Un consultorio online a tu medida</h3>
                    <p>Los profesionales de la salud contarán con un consultorio online y dirán adiós a procesos rutinarios que han sido automatizados con el fin de optimizar su tiempo y brindar atención de calidad a sus pacientes.</p>
                    <a href="http://www.google.com"  className="btn btn-primary px-5">Descargar App</a>
                </article>
                <div className="col-md-6">
                    <img className="img-fluid" alt={""} src={ContentImg}/>
                </div>
            </div>
        </div>
    </div>
)