import React from "react";
import Phone from "../images/4456416.png"
import "../styles/download.scss"

export const Download = () => {
    return (
        <div className="download bg-gradient-my-good-app">
            <div className="container">
                <div className="row">
                    <div className="col-lg-5 d-flex align-items-center">
                        <div className="my-5 mx-auto mx-lg-0 text-lg-start text-center">
                            <h2 className="text-uppercase text-white mb-4">
                                Descarga Gratis <br/>
                                Nuestra App
                            </h2>
                            <div className="btn-area">
                                <a href={"http://www.google.com"} className="btn btn-dark me-3">
                                    Google Play
                                </a>
                                <a href={"http://www.google.com"} className="btn btn-dark">
                                    App Store
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7">
                        <img className="img-fluid phone" src={Phone} alt=""/>
                    </div>
                </div>
            </div>
        </div>
    )
}