import React from "react";
import useWindowSize from "../hooks/use-window-size";
import "../styles/hero.scss"
import { Link } from "gatsby";

export const Hero = () => {
    const size = useWindowSize();

    return (
        <div className="hero bg-gradient-my-good-app  d-flex align-items-center"
             style={{minHeight: `${size.height - 76}px`}}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 side-logo">
                        <h1 className="text-white">
                            SOMOS TU <br/>
                            <strong>COMUNIDAD DE SALUD</strong>
                        </h1>
                        <h2 className="text-white">
                            My Good App es una novedosa plataforma tecnológica de atención médica
                        </h2>
                        <div className="btn-area">
                            <Link to="/" className="btn btn-white btn-lg">Registrate Gratis</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}