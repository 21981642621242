import React from "react"
import Fade from 'react-reveal/Fade';

export const Corporation = () => (
    <div className="bg-white" style={{fontWeight: "lighter"}}>
        <Fade up>
            <div className="container py-5">
                <div className="row text-center text-lg-start">
                    <div className="col-lg-5 offset-lg-1 d-flex align-items-center justify-content-center justify-content-lg-start">
                        <h2 className="text-uppercase mb-lg-3">Servicio empresarial</h2>
                    </div>
                    <div className="col-lg-5">
                        <p>
                            My Goood App ofrece un servicio innovador a las empresas para el cumplimiento de sus deberes en materia de salud laboral.
                        </p>
                        <p>
                            My Goood App brinda al empleador toda la información relevante del paciente bajo claros estándares éticos.
                        </p>
                    </div>
                </div>
            </div>
        </Fade>
    </div>
);